import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { FaAngleUp, FaBars, FaTimes } from 'react-icons/fa'

class NavCollapsed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      top: false,
      menu: false,
    }
    this.scrollTopListener = this.scrollTopListener.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollTopListener)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollTopListener)
  }

  scrollTopListener() {
    this.setState({ top: window.pageYOffset === 0 ? true : false})
  }

  render() {
    return (
      <div className="row sticky-collapsed">
        <div className="col-md-12">
          <nav className="menu-collapsed">
            <p><span onClick={() => this.setState({ menu: !this.state.menu })}>
              { this.state.menu ? <FaTimes/> : <FaBars/> }
            </span> Menu</p>
            <div className={"slide-up" + (this.state.menu ? " slide-down" : "")}>
              <div>
                <ul className="navid">
                  <li className={this.props.navCurrent === "" ? "current" : ""}><a href="/">Home</a></li>
                  <li className={this.props.navCurrent === "method" ? "current" : ""}><AnchorLink href='#method'>Our Methods</AnchorLink></li>
                  <li className={this.props.navCurrent === "work" ? "current" : ""}><AnchorLink href='#work'>Our Clients</AnchorLink></li>
                  <li className={this.props.navCurrent === "service" ? "current" : ""}><AnchorLink href='#service'>Our Services</AnchorLink></li>
                  <li className={this.props.navCurrent === "prebuilt" ? "current" : ""}><AnchorLink href='#prebuilt'>Our Pre-built Systems</AnchorLink></li>
                  <li className={this.props.navCurrent === "opensource" ? "current" : ""}><AnchorLink href='#opensource'>Open Source Software</AnchorLink></li>
                  <li className={this.props.navCurrent === "contact" ? "current" : ""}><AnchorLink href='#contact'>Contact</AnchorLink></li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
        { this.props.showToTop && <AnchorLink className="top" href='#top'><FaAngleUp /></AnchorLink> }
      </div>
    )
  }
}

export default NavCollapsed
