import React from 'react'
import Slider from "react-slick";

import WOW from "wowjs";
import AnchorLink from 'react-anchor-link-smooth-scroll'

import main from '../images/slider/1.jpg'

class MainSlider extends React.Component {
  
  componentDidMount() {
    this.wow()
  }

  wow() {
    new WOW.WOW({
      live: false
    }).init();
  }

  render() {
    var settings = {
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      arrows: false,
      beforeChange: () => this.wow()
    };

    return (
      <section className="main-slider-area" id="home">
        <Slider {...settings}>
          <div className="slider 1">
            <img className="image" src={main} alt="main slider" title="#caption1" />
            <div className="content">
              <div className="title wow zoomIn" data-wow-duration=".9s" data-wow-delay=".5s">
                <span>WELCOME To </span>
                <span className="logo-object">Object</span>
                <span className="it">IT</span>
              </div>
              <div className="sub-title wow zoomIn" data-wow-duration="1.1s" data-wow-delay=".5s">
                Custom Software Developers
              </div>
              <div className="what wow zoomIn" data-wow-duration="1.5s" data-wow-delay=".5s">
                <AnchorLink href="#work">What we do</AnchorLink>
              </div>
            </div>
          </div>
          <div className="slider 2">
            <img className="image" src={main} alt="main slider" title="#caption2" />
            <div className="content">
              <div className="title wow slideInRight" data-wow-duration="2s" data-wow-delay="0s">
                <span>WELCOME To </span>
                <span className="logo-object">Object</span>
                <span className="it">IT</span>
              </div>
              <div className="sub-title wow slideInRight" data-wow-duration="3s" data-wow-delay="0s">
                Custom Software Developers
              </div>
              <div className="what wow zoomIn" data-wow-duration="1.5s" data-wow-delay=".5s">
                <AnchorLink href="#work">What we do</AnchorLink>
              </div>
            </div>
          </div>
          <div className="slider 3">
            <img className="image" src={main} alt="main slider" title="#caption3" />
            <div className="content">
              <div className="title wow slideInRight" data-wow-duration="2s" data-wow-delay="0s">
                <span>WELCOME To </span>
                <span className="logo-object">Object</span>
                <span className="it">IT</span>
              </div>
              <div className="sub-title wow slideInRight" data-wow-duration="3s" data-wow-delay="0s">
                Custom Software Developers
              </div>
              <div className="what wow zoomIn" data-wow-duration="1.5s" data-wow-delay=".5s">
                <AnchorLink href="#work">What we do</AnchorLink>
              </div>
            </div>
          </div>
        </Slider>
      </section>
    );
  }
}

export default MainSlider
