import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { FaAngleUp } from 'react-icons/fa'

class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      top: false
    }
    this.scrollTopListener = this.scrollTopListener.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollTopListener)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollTopListener)
  }

  scrollTopListener() {
    this.setState({ top: window.pageYOffset === 0 ? true : false})
  }

  render() {
    return (
      <div className="nav_areas">
        <div className={"nav_area " + (this.state.top ? "postfix" : "prefix")}>
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-2 col-xs-4">
                <div className="logo">
                  <span className="main-logo-object">Object</span><span className="main-logo-IT">IT</span>
                </div>
              </div>
              <div className="col-md-10 col-sm-10 col-xs-8">
                <nav className="menu">
                  <ul className="navid">
                    <li className={this.props.navCurrent === "" ? "current" : ""}><a href="/">Home</a></li>
                    <li className={this.props.navCurrent === "method" ? "current" : ""}><AnchorLink href='#method' offset='99'>Our Methods</AnchorLink></li>
                    <li className={this.props.navCurrent === "work" ? "current" : ""}><AnchorLink href='#work' offset='99'>Our Clients</AnchorLink></li>
                    <li className={this.props.navCurrent === "service" ? "current" : ""}><AnchorLink href='#service' offset='99'>Our Services</AnchorLink></li>
                    <li className={this.props.navCurrent === "prebuilt" ? "current" : ""}><AnchorLink href='#prebuilt' offset='99'>Our Pre-built Systems</AnchorLink></li>
                    <li className={this.props.navCurrent === "opensource" ? "current" : ""}><AnchorLink href='#opensource' offset='99'>Open Source Software</AnchorLink></li>
                    <li className={this.props.navCurrent === "contact" ? "current" : ""}><AnchorLink href='#contact' offset='99'>Contact</AnchorLink></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {this.props.showToTop && <AnchorLink className="top" href='#top'><FaAngleUp /></AnchorLink>}
      </div>
    )
  }
}

export default Nav
