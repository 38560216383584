import React from 'react'
import line from '../images/logo/line-1.png'

import SingleMethod from './our_methods_single_method'

var single_content = [
  [
    "Consulting Approach",
    "Object IT has a focus on deeply understanding your objectives, determining the best way to meet those objectives and constantly consulting with the client to ensure they are fully involved in the development process.",
    "briefcase"
  ], [
    "Agile Methodology",
    "Agile and SCRUM framework is a best practice, iterative software development framework which is designed to overcome the notorious risks of traditions software development methods.",
    "line-chart"
  ], [
    "Languages / Frameworks",
    "Object IT developers are proficient in many languages and frameworks.This allows us to use and recommend the best tools for your job and work in specific languages if the client requires. \n\n",
    "code"
  ], [
    "Test Driven Development",
    "We design and build our systems methodically with a focus on automated testing, resilience and flexibility from the beginning.",
    "thumbs-o-up"
  ], [
    "Hosting and Storage",
    "Heroku, Amazon AWS are our preferred hosting solutions.\n We use robust cloud infrastructure which means our systems work reliably and cost effectively, all the time",
    "cloud-upload"
  ], [
    "Databases",
    "We like to use schemaless databases like MongoDB that allows our systems to be much more flexible.We are also experts with PostgreSQL, SQLite, SQL.",
    "database"
  ]
]

const renderSingles = () => (
  <div className="row">
    {
      single_content.map((arr, i) => {
        return <SingleMethod key={i} title={arr[0]} description={arr[1]} icon={arr[2]} />
      })
    }
  </div>
)

const OurMethods = () => (
  <div className="service_area" id="method">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section_title">
            <h2>Our <span>Methods</span></h2>
            <img src={line} alt="" />
            <p>
              We are a custom software developer who specialises in rapid and cost effective online app development.
			        We like to think of ourselves as the Skunk Works of software.
            </p>
            <p>
              We use modern software development techniques to allow us to deliver systems that work as intended, on time
              and on budget.  These methods are all designed to reduce development time and costs and increase ease of use,
			        quality, functionality and reliability.  Our software is built to be modular, easy to change, easy to integrate.
            </p>
          </div>
        </div>
      </div>

      { renderSingles() }        
    </div>
  </div>
)

export default OurMethods
