import React from 'react'
import line from '../images/logo/line-1.png'

var services = [
  "Ruby on Rails App Development",
  "Python App Development",
  "React App Development",
  "Java App Development",
  "iPad App Development",
  "iPhone App Development",
  "Web Design",
  "UX Design",
  "UI Design",
  "Agile Web Development with Rails",
  "IT Consulting",
  "Test Driven Development",
  "Custom Application Development",
  "Responsive Web Development",
  "Software Development",
  "Mobile Application Development",
  "Android App Development",
  "Web Development",
  "Low-code Systems",
  "Demonstrator / Proof of Concept",
  "Startups, Restarts, Remakes",
  "Google Sheet Systems"
]

const renderServices = () => (
  <div className="row">
    {
      services.map((service, i) => {
        return (
          <div className="col-md-4 col-sm-6 col-xs-12" key={i}>
            <div className="single_service service-tile">
              <div className="service_content">
                <h3 className="service-title">{service}</h3>
              </div>
            </div>
          </div>
        )
      })
    }
  </div>
)

const OurServices = () => (
  <div className="service_area" id="service">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section_title">
            <h2>Our <span>Services</span></h2>
            <img src={line} alt="" />
          </div>
        </div>
      </div>

      { renderServices() }
    </div>
  </div>
)

export default OurServices
