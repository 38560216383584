import PropTypes from 'prop-types'
import React from 'react'
import { FaBriefcase, FaChartLine, FaCode, FaThumbsUp, FaCloudUploadAlt, FaDatabase } from 'react-icons/fa'

const iconSwitch = (icon) => {
  switch (icon) {
    case "briefcase":
      return <FaBriefcase/>
    case "line-chart":
      return <FaChartLine/>
    case "code":
      return <FaCode/>
    case "thumbs-o-up":
      return <FaThumbsUp/>
    case "cloud-upload":
      return <FaCloudUploadAlt/>
    case "database":
      return <FaDatabase/>
    default:
      break
  }
}

const SingleMethod = ({ title, description, icon }) => (
  <div className="col-md-4 col-sm-6 col-xs-12">
    <div className="single_service services-tiles">
      <div className="service_icon">
        { iconSwitch(icon) }
      </div>
      <div className="service_content">
        <h3>{title}</h3>
        <p style={{'whiteSpace': 'pre-wrap'}}>{description}</p>
      </div>
    </div>
  </div>
)

SingleMethod.propTypes = {
  siteTitle: PropTypes.string,
}

SingleMethod.defaultProps = {
  siteTitle: ``,
}

export default SingleMethod
