import React from 'react'
import line from '../images/logo/line-1.png'

var prebuilt_systems = [
  [
    "Online Training Shops",
    "We have existing systems which allow you to sell Online Training to your clients."
  ], [
    "Online Induction / Training Systems",
    "We have highly developed Online Induction / Training platforms which allow you to deliver training to your work force or sell this service to your clients."
  ], [
    "Airport Visitor Tracking System",
    "We have a pre-built Airport Visitor Tracking system for Airports."
  ]
]

const renderPrebuilt = (title, description, i) => (
  <div className="col-md-4  word code col-sm-6 col-xs-12 grid-item" key={i}>
    <div className="single_portfolio">
      <div className="single_portfolio_thumb">
        <div className="portfolio_info prebuilt-tile">
          <h3><a className="prebuilt-text" href="single-portfolio.html">{title}</a></h3>
        </div>
        <div className="portfolio_content">
          <div className="portfolio_content_hover">
            <div className="prebuilt-info-text">
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const OurPrebuiltSystems = () => (
  <div className="portfolio_area" id="prebuilt">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section_title">
            <h2>Our <span>Pre-built Systems</span></h2>
            <img src={line} alt="" />
            <p>Object IT has a number of systems which can be purchased off the self
        and or customised at a fraction of the cost that it would take to develop from scratch.  </p>
          </div>
        </div>
      </div>

      <div className="row prot_image_load">
        {
          prebuilt_systems.map((arr, i) => {
            return renderPrebuilt(arr[0], arr[1], i)
          })
        }
      </div>
    </div>
  </div>
)

export default OurPrebuiltSystems
