import React from 'react'
import line from '../images/logo/line-1.png'
import ReCAPTCHA from "react-google-recaptcha";

const recaptchaRef = React.createRef();

class OurContact extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showMessage: false,
      name: '',
      email: '',
      phone: '',
      subject: '',
      comment: ''
    }
  }

  encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }




  handleSumbit = e => {
    recaptchaRef.current.execute();
    e.preventDefault();
  };

  postMessage = () => {
    var body = {
      "form-name" : "contact",
      ...this.state
    }

    delete (body.showMessage)
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode(body)
    })
      .then(() => {
        this.setState({ showMessage:true })
        console.log('submitted form successfully')
      })
      .catch(error => alert(error));
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleInputChange = this.handleInputChange.bind(this);
  handleSubmit = this.handleSumbit.bind(this);


  render () {
    return (
    <div className="contact_area" id="contact">
      <div className="container">

        <div className="row">
          <div className="col-md-12">
            <div className="section_title">
              <h2>Our <span>Contact</span></h2>
              <img src={line} alt="" />
              <p>Tell us a little about your project, and we'll get back to you.
                If you'd like to speak to someone right away, just call us at +61 7 3102 8324.</p>
            </div>
          </div>
        </div>

        <form name="contact" data-netlify="true" onSubmit={this.handleSumbit} data-netlify-honeypot="bot-field">

          <div className="row">
            <div className="col-md-5">
              <div className="contact_form">
                <input type="hidden" name="form-name" value="contact" />
                <input type="hidden" name="showMessage" value={this.state.showMessage} />
                <div className="input_boxes">
                  <input type="text" name="name" placeholder="Name" value={this.state.name} onChange={this.handleInputChange}/>
                </div>
                <div className="input_boxes">
                  <input type="email" name="email" placeholder="Email" value={this.state.email} onChange={this.handleInputChange} />
                </div>
                <div className="input_boxes">
                  <input type="text" name="phone" placeholder="Phone"  value={this.state.phone} onChange={this.handleInputChange} />
                </div>
                <div className="input_boxes">
                  <input type="text" name="subject" placeholder="Subject" value={this.state.subject} onChange={this.handleInputChange} />
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="message_form">
                <div className="input_boxes textarea">
                  <textarea className="message_box" name="comment" placeholder="Type Your Message" value={this.state.comment} onChange={this.handleInputChange}></textarea>
                </div>
                <button type="submit" name="ok" className="sbuton">Message</button>
              </div>
            </div>


            <div className={this.state.showMessage ? 'col-md-12 alert-success' : 'hidden'} >
              <p className="h2 text-center">Thank you for your message</p>
            </div>
            <br/>
            <div className="col-md-7">
              <ReCAPTCHA
                ref={recaptchaRef}
                badge={'inline'}
                size="invisible"
                sitekey="6LdGF44UAAAAAFLgCr0hGeIBYj2exJRqV58RBVEZ"
                onChange={this.postMessage}
              />
            </div>

          </div>
        </form>

      </div>
    </div>
    )
  }
}

export default OurContact
