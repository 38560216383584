import PropTypes from 'prop-types'
import React from 'react'
import { FaNotesMedical, FaPen, FaBuilding, FaBriefcase, FaCartArrowDown, FaUniversity, FaDesktop, FaMapMarkerAlt, FaLink, FaPencilAlt, FaStreetView, FaUserSecret, FaCreditCard, FaUmbrella, FaTable } from 'react-icons/fa'

import carelynx from '../images/portfolio/carelynx.png'
import yodal from '../images/portfolio/yodal.png'
import coreLogic from '../images/portfolio/core-logic.jpg'
import excelToWebApp from '../images/portfolio/excel-to-web-app.jpg'
import angloAmerican from '../images/portfolio/anglo-american.png'
import teck from '../images/portfolio/teck.png'
import newmont from '../images/portfolio/newmont.jpg'

const imageSwitch = (image) => {
  switch (image) {
    case "carelynx":
      return <img src={carelynx} alt={image} />
    case "yodal":
      return <img src={yodal} alt={image} />
    case "core-logic":
      return <img src={coreLogic} alt={image} />
    case "excel-to-web-app":
      return <img src={excelToWebApp} alt={image} />
    case "anglo-american":
      return <img src={angloAmerican} alt={image} />
    case "teck":
      return <img src={teck} alt={image} />
    case "newmont":
      return <img src={newmont} alt={image} />
    default:
      break
  }
}

const iconSwitch = (icon) => {
  switch (icon) {
    case "notes-medical":
      return <FaNotesMedical/>
    case "pen":
      return <FaPen/>
    case "building":
      return <FaBuilding/>
    case "briefcase":
      return <FaBriefcase/>
    case "table":
      return <FaTable/>
    case "cart-arrow-down":
      return <FaCartArrowDown />
    case "university":
      return <FaUniversity/>
    case "desktop":
      return <FaDesktop/>
    case "map-marker":
      return <FaMapMarkerAlt/>
    case "link":
      return <FaLink/>
    case "pencil-square-o":
      return <FaPencilAlt/>
    case "street-view":
      return <FaStreetView/>
    case "user-secret":
      return <FaUserSecret/>
    case "credit-card":
      return <FaCreditCard/>
    case "umbrella":
      return <FaUmbrella/>
    default:
      break
  }
}

const GalleryItem = ({ title, description, icon, image }) => (
  <div className="col-md-4 col-sm-6 col-xs-12">
    <div className="client_item">
      { 
        icon && !image &&
        <div class="icon">
          {iconSwitch(icon)}
        </div>
      }
      {
        image &&
        <div class="image">
          {imageSwitch(image)}
        </div>
      }
      <div className="content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  </div>
)

GalleryItem.propTypes = {
  siteTitle: PropTypes.string,
}

GalleryItem.defaultProps = {
  siteTitle: ``,
}

export default GalleryItem