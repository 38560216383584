import React from 'react'

import GalleryItem from './our_works_gallery_item'
import line from '../images/logo/line-1.png'

class OurWorks extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      gallery_items: [
        [
          "Carelynx Clinical Monitoring System",
          "Clinical Management web application for Residential aged care.",
          "notes-medical",
          "carelynx"
        ],
        [
          "Yodal",
          "Online expert system that helps people make a Will and do Estate Planning.",
          "pen",
          "yodal"
        ],
        [
          "CoreLogic",
          "CoreLogic (RP Data) is an international Real Estate / IT company. We can't tell you what we make for them.",
          "building",
          "core-logic"
        ],
        [
          "Anglo American and DeBeers",
          "We make numerous custom systems for Anglo including - Risk Planning, Training, online forms, Excel Spreadsheets to web apps, etc",
          "briefcase",
          "anglo-american"
        ],
        [
          "Teck Resources Limited",
          "We have developed a Risk Planning System. This system is available for purchase.",
          "table",
          "teck"
        ],
        [
          "Newmont Corporation",
          "We have developed a Risk Planning System. This system is available for purchase.",
          "table",
          "newmont"
        ],
        [
          "Converting Excel Spreadsheets to web apps",
          "We can very cost effectively convert Excel and Google Sheets spreadsheets into fully functional web applications.",
          "table",
          "excel-to-web-app"
        ], 
        [
          "Risk Planning System",
          "This system allows rapid development of project risk registers.",
          "table"
        ],
        [
          "Online Training Shops",
          "Online systems that take payment for and deliver online training.",
          "cart-arrow-down"
        ], [
          "Expert Systems",
          "Yodal - online system designed to make it easy for financial advisors to help their client produce a will.",
          "university"
        ], [
          "Online Induction / Training Systems",
          "Online platforms that deliver and track multi media online training.",
          "desktop"
        ], [
          "Location / Map Based Apps",
          "Systems that use maps, device locations and designated areas to facilitate useful functions like object tracking, people tracking, designating areas on a map, providing location based information / popups and allowing location based information inputs / reports.",
          "map-marker"
        ], [
          "Competency tracking system",
          "People with assocated competencies, files and photos can be stored on a system and retrieved from anywhere on site.  This allows you to look up a person, see their competencies, and qualification doc (files) on a phone.",
          "link"
        ], [
          "Object Tracking Systems",
          "Objects with assocated information, location, files and photos can be stored on a system and retrieved from anywhere on site.  This allows you to look up an object and see its information, location and files on a phone.",
          "pencil-square-o"
        ], [
          "Airport Visitor Tracking",
          "Airports have a requirement to record details of anyone entering a restricted area on an Airport and track them. This system uses automated feature to simplify this process.",
          "street-view"
        ], 
        [
          "Security System Addons",
          "These systems allow information from older security system to be viewed online and allows information to be used to easily produce customised reports.",
          "user-secret"
        ], [
          "Payment Systems",
          "Allows our clients to easily take automated payments online.",
          "credit-card"
        ], [
          "Online Insurance Quotes and Purchases",
          "Online systems that take information, produce a quote based on that information and allow the customer to make purchase and receive all relevant insurance documentation automatically.",
          "umbrella"
        ]
      ]
    }
  }

  renderGalleryItems() {
    return (
      <div className="row">
        {
          this.state.gallery_items.map((arr, i) => <GalleryItem key={i} title={arr[0]} description={arr[1]} icon={arr[2]} image={arr[3]} />)
        }
      </div> 
    );
  }

  render() {
    return (
      <div className="portfolio_area" id="work">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section_title">
                <h2>Our <span>Clients</span></h2>
                <img src={line} alt="" />

                <p>We have made a wide variety of web-based applications for our various clients. Below, you'll see a list of some of our clients and some of the systems we have developed.</p>
                <p>Object IT is always making new and different systems because we specialize in custom software development.</p>
              </div>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-md-12">
              <div className="portfolio_nav">
                <ul className="filter-menu">
                  <li className="current_menu_item" data-filter="*" data-toggle="tooltip" data-placement="top" title="10">All Works</li>
                </ul>
              </div>
            </div>
          </div> */}

          { this.renderGalleryItems() }
          
        </div>
      </div>
    )
  }
}

export default OurWorks
